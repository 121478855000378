/* Jurta Color Palette */

$white: #FFFFFF;
$whiteEdgar: #EDEDED;
$antiFlashWhite: #F0F0F0;
$whiteSmoke: #B5B5B5;
$veryBlack: #212025;
$raisinBlack: #212121;
$veryBlack: #171717;
$darkCharcoal: #333333;
$gray: #333237;
$graySecondary: #BABABA;
$grayDisabled: #464550;
$light-gray: #4D4B53;
$ash-gray: #6A6877;
$silverGray: #A7A7A7;
$accent: #26BCBC;
$orange: #FA6009;
$red: #FF3333;
$purple-gradient: linear-gradient(to right, #572BA3, #8D48FF);
$yellow: #FAC600;
$purple: #8D4BFF;

$blue-gradient: linear-gradient(90deg, #12B3EB 0%, #5460F9 100%);
$orange-gradient: linear-gradient(90deg, #F6B72D 0%, #EA5757 100%);
$red-gradient: linear-gradient(90deg, #FF0F7B 0%, #F89B29 100%);
$green-gradient: linear-gradient(90deg, #82F4B1 0%, #30C67C 100%);
$purple-gradient: linear-gradient(90deg, #D397FA 0%, #8364E8 100%);
$yellow-gradient: linear-gradient(90deg, #E4E95D 0%, #E89D1F 100%);

